<template>
  <v-main class="fill-height">
    <v-container fluid fill-height class="pa-0">
      <v-img :src="bgImage" class="fill-height" style="max-height: 100vh">
        <v-overlay absolute :value="true">
          <v-row class="align-center justify-center fill-height">
            <v-col cols="12">
              <div class="text-center">
                <v-img
                  :src="srcLogo"
                  :height="logoHeight"
                  :aspect-ratio="aspectRatio"
                  class="v-image--auto"
                />
              </div>

              <ValidationObserver ref="observer" slim v-slot="{ valid }">
                <v-card
                  light
                  class="pde-login-card mt-4"
                  :class="{ avatar: !!profile.avatar }"
                >
                  <v-img
                    :src="profile.avatar"
                    contain
                    aspect-ratio="1"
                    v-if="profile.avatar"
                  />

                  <v-slide-x-transition leave-absolute>
                    <component :is="sComp" :valid="valid" />
                  </v-slide-x-transition>
                </v-card>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-overlay>
      </v-img>
    </v-container>
  </v-main>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";
import { ConfigModule } from "@/store/config";
import AuthMixin from "@/mixins/AuthMixin";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import LoginForm from "@/modules/account/components/LoginForm.vue";
import ForgetForm from "@/modules/account/components/ForgetForm.vue";
import RestoreForm from "@/modules/account/components/RestoreForm.vue";

@Component({
  components: {
    LoginForm,
    ForgetForm,
    RestoreForm,
  },
})
export default class AccountLogin extends Mixins(AuthMixin) {
  mounted() {
    LayoutModule.setLayout("login");
  }

  get bgImage() {
    return ConfigModule.settings.preview_image || "@/assets/images/login.jpg";
  }

  get smLogo() {
    return require("@/assets/images/logo_200.png");
  }

  get mdLogo() {
    return require("@/assets/images/logo_420.png");
  }

  get lgLogo() {
    return require("@/assets/images/logo.png");
  }

  get srcLogo() {
    if (this.$vuetify.breakpoint.xs) {
      return this.smLogo;
    }

    return this.$vuetify.breakpoint.mdAndDown ? this.mdLogo : this.lgLogo;
  }

  get aspectRatio() {
    return 600 / 113;
  }

  get profile() {
    const sProfile = localStorage.getItem("user");
    const obProfileData = sProfile ? JSON.parse(sProfile) : {};
    return new Profile(obProfileData);
  }

  get sComp() {
    if (!this.$route.name || this.$route.name == "account.login") {
      return "login-form";
    }

    return this.$route.name == "account.restore"
      ? "restore-form"
      : "forget-form";
  }

  get logoHeight() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 80;
    }

    return 120;
  }
}
</script>
