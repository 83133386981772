<template>
  <v-form>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("lost.password.page.title") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <form-field-email v-model="sEmail" @save="onRestorePassword" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" :to="{ name: 'account.login' }" text>
        {{ $t("back.to.login") }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        rounded
        :disabled="!valid"
        @click="onRestorePassword"
        :loading="btnLoading"
      >
        {{ $t("request.password") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import AuthMixin from "@/mixins/AuthMixin";
import { Auth } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class ForgetForm extends Mixins(AuthMixin) {
  sEmail = "";
  btnLoading = false;

  @Prop(Boolean) readonly valid!: boolean;

  async onRestorePassword() {
    if (!this.valid) {
      return;
    }

    this.btnLoading = true;

    const obAuth = new Auth();
    const obResponse = await obAuth.restorePassword(this.sEmail.toLowerCase());
    const obResponseData = obResponse.getData();
    if (obResponseData.message) {
      this.flashSuccess(obResponseData.message);
    }

    this.btnLoading = false;
  }
}
</script>
